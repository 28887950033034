<template>
  <section>

    <!-- header row -->
    <b-row class="content-header">
      <b-col cols="12">
        <div class="breadcrumb-wrapper">
          <b-breadcrumb>
            <b-breadcrumb-item :to="{ name: 'dashboard' }">
              <feather-icon
                icon="HomeIcon"
                size="16"
                class="align-text-top"
              /> Dashboard
            </b-breadcrumb-item>
            <b-breadcrumb-item :active="true">
              Campaigns
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </b-col>
      <b-col md="6">
        <h2 class="py-2">
          <feather-icon
            icon="AwardIcon"
            size="24"
          />
          Campaigns
        </h2>
      </b-col>
      <b-col
        md="6"
        class="mb-2 text-right pt-2"
      >
        <b-button
          v-if="!adminAccess"
          variant="primary"
          :to="{ name: 'create-campaign' }"
        >
          <feather-icon
            icon="PlusIcon"
            size="16"
            class="align-text-top"
          />  Add New
        </b-button>
        <b-button
          v-if="adminAccess && selectedCompany"
          variant="primary"
          :to="{ name: 'create-campaign-root', params: { companyId: selectedCompany } }"
        >
          <feather-icon
            icon="PlusIcon"
            size="16"
            class="align-text-top"
          />  Add New
        </b-button>
      </b-col>
    </b-row>

    <!-- companies -->
    <b-row v-if="adminAccess">
      <b-col md="4">
        <RootCompanySelect
          v-model="selectedCompany"
          class="mb-2"
          load-default
          @setCompany="setCompanyId"
        />
      </b-col>
    </b-row>

    <!-- campaign cards -->
    <b-row v-if="visibleCampaigns && visibleCampaigns.length <= 0">
      <b-col>
        No items to show.
      </b-col>
    </b-row>
    <b-row
      v-if="visibleCampaigns && visibleCampaigns.length > 0"
      class="match-height"
    >
      <!-- card -->
      <b-col
        v-for="campaign in visibleCampaigns"
        :key="campaign.id"
        md="3"
        sm="4"
      >
        <b-card
          :img-alt="campaign.name"
          img-top
          no-body
          class="campaign-card"
        >
          <b-link
            :href="'/campaigns/' + campaign.id"
            disabled
            @mouseover="handleHover(campaign.id, true)"
            @mouseleave="handleHover(campaign.id, false)"
          >
            <b-overlay
              v-b-toggle="`sidebar-campaign-${campaign.id}`"
              :show="hoverState[campaign.id]"
              opacity="0.5"
            >
              <b-card-img
                :src="require('@/assets/images/slider/08.jpg')"
                class="card-image"
              />
              <template #overlay>
                <b-button variant="primary">
                  Quick View
                </b-button>
              </template>
            </b-overlay>
          </b-link>
          <b-card-body>
            <b-card-title>{{ campaign.name }}</b-card-title>
            <b-card-text>
              <div class="start-date mb-1">
                <small class="text-muted">Start Date</small><br>
                {{ getReadableDate(campaign.start_date) }}
              </div>
              <div class="end-date mb-1">
                <small class="text-muted">End Date</small><br>
                {{ getReadableDate(campaign.end_date) }}
              </div>
            </b-card-text>
          </b-card-body>
          <b-card-footer class="text-center">
            <b-button
              href="#"
              variant="outline-primary"
              class="mr-1"
            >
              Analytics
            </b-button>
            <b-button
              :href="'/campaigns/' + campaign.id"
              variant="primary"
            >
              View
            </b-button>
          </b-card-footer>
        </b-card>

        <b-sidebar
          :id="`sidebar-campaign-${campaign.id}`"
          width="40rem"
          right
          backdrop
          shadow
          lazy
        >
          <view-campaign
            :campaign-id="campaign.id"
            class="sidebar"
          />
        </b-sidebar>
      </b-col>
    </b-row>

  </section>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol,
  // BTable, BFormGroup, BFormSelect, BPagination,
  // BInputGroup, BFormInput, BInputGroupAppend,
  BButton, BLink, BOverlay, BSidebar,
  BCard, BCardImg, BCardBody, BCardTitle, BCardText, BCardFooter,
  VBToggle,
} from 'bootstrap-vue'
import ViewCampaign from '@/views/campaigns/ViewCampaign.vue'
import RootCompanySelect from '@/views/components/root-company-select/RootCompanySelect.vue'

export default {
  directives: {
    'b-toggle': VBToggle,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BButton,
    BLink,
    BOverlay,
    BSidebar,
    BCard,
    BCardImg,
    BCardBody,
    BCardTitle,
    BCardText,
    BCardFooter,

    ViewCampaign,
    RootCompanySelect,
  },
  data() {
    return {
      hoverState: {},
      userData: JSON.parse(localStorage.getItem('userData')),
      adminAccess: false,
      selectedCompany: null,
    }
  },
  computed: {
    visibleCampaigns() {
      return this.$store.getters['campaigns/getAllCampaigns']
    },
  },
  created() {
    if (this.userData.role.id === 1) {
      this.adminAccess = true
    } else {
      // fetch campaigns if not root user
      this.$store.dispatch('campaigns/fetchAllCampaigns')
        .then(() => {
          if (this.visibleCampaigns) {
            Object.values(this.visibleCampaigns).forEach(campaign => {
              this.$set(this.hoverState, campaign.id, false) // set initial hover state
            })
          }
        })
    }
  },
  methods: {
    handleHover(campaignId, isHovered) {
      // change hover state over campaign image
      this.$set(this.hoverState, campaignId, isHovered)
    },
    getReadableDate(dateStr) {
      const date = new Date(dateStr)
      return `${date.toLocaleDateString([], { dateStyle: 'long' })} ${date.toLocaleTimeString([], { timeStyle: 'short' })}`
    },
    setCompanyId(companyId) {
      if (companyId) {
        this.selectedCompany = companyId
        this.$store.dispatch('campaigns/fetchCampaignsAsRoot', companyId)
          .then(() => {
            if (this.visibleCampaigns) {
              Object.values(this.visibleCampaigns).forEach(campaign => {
                this.$set(this.hoverState, campaign.id, false) // set initial hover state
              })
            }
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.b-sidebar-body .row {
  margin-left: 0;
  margin-right: 0;
}

.campaign-card .card-image {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
